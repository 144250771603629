:root {
  --radius: 50;
  --PI: 3.14159265358979;
  --circumference: calc(var(--PI) * var(--radius) * 2px)
}

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'DM Sans', sans-serif;
}

.ant-layout {
  font-family: 'DM Sans', sans-serif !important;

}

.clickable-row {
  cursor: pointer;
}

.ant-checkbox .ant-checkbox-checked {
  color: #6633FF !important;
}

.ql-toolbar.ql-snow {
  font-family: 'DM Sans', sans-serif !important;

}

.ql-container.ql-snow {
  font-family: 'DM Sans', sans-serif !important;
  height: 200px !important;
}


:root {
  --radius: 50;
  --PI: 3.14159265358979;
  --circumference: calc(var(--PI) * var(--radius) * 2px)
}

html {
  background-color: #ccc;
  font-family: sans-serif;
}

.container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

h1 {
  color: #444;
}

.circle-svg circle {
  stroke: #330066;
  stroke-width: 4;
  fill: transparent;
  transform-origin: center;
  stroke-dasharray: var(--circumference);
  animation: spinner 2s ease-out infinite;
}

@keyframes spinner {
  from {
    stroke-dashoffset: var(--circumference);
    stroke: #330066;
    transform: rotateZ(0deg)
  }

  to {
    stroke-dashoffset: calc(var(--circumference) * -1);
    stroke: #6633FF;
    transform: rotateZ(720deg)
  }
}


a {
  display: -webkit-inline-flex !important;
}

/* .react-datepicker__input-container {
  width: 600px !important;
} */
.react-datepicker__input-container .date {
  width: 330px !important;
  border: 1px solid #ced4da !important;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, .85);
  font-weight: 500;
  font-size: 16px;
  text-wrap: wrap !important;
}

.ant-select-selector {
  width: 330px !important;
  border: 1px solid #ced4da !important;
  padding: .280rem .75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  appearance: none !important;
  border-radius: .375rem !important;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.jobtitle {

  font-family: Inter;
  font-size: 26px;
  color: #6633FF;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
}

.range .range-slider__range {
  background: #6633FF !important;
}

.range .range-slider__range[data-active] {
  background: #6633FF !important;
}

.range .range-slider__thumb {
  background: #6633FF !important;
}

.addi {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  color: #9900FF;

}

.stsearch-box input,
.stsearch-box input :focus,
.stsearch-box input :active {
  width: 100%;
  font-family: Inter !important;
  box-sizing: border-box;
  padding: 0 !important;
  border: none !important;
  border-radius: 0;
}

.stdropdown-menu {
  font-family: Inter !important;
}

.role {
  min-height: 335px;
  width: 332px;
  background-color: #ECE2F3;
  padding: 18px 14px;
  border-radius: 12px;
  border: none;
  margin-bottom: "10px";

}

.role_name {
  font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: -0.23999999463558197px;
color: #330066

}

.role_age, .role_gender {
padding: 2px 6px;
background-color: white;
border-radius : 90px;
margin-bottom: 10px;
color: #330066;
font-size: 8px;

}

.role_desc {
  font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 20px;
letter-spacing: -0.23999999463558197px;
text-align: left;
margin: 10px 0;
word-wrap: break-word;
white-space: normal;
}

.role_tag {
  background-color: #330066;
  color: white;
  padding: 2px 6px;
  margin-bottom: 10px;
}

.role_price {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.23999999463558197px;
  color: #330066
  
  }

  .modal {
    z-index: 900 !important;  /* Lower the modal z-index */
  }
  
  .modal-backdrop {
    z-index: 800 !important;  /* Lower the backdrop z-index */
  }

  .talent .ant-select-selector {
    min-height: 100px !important;
    align-items: start;
  }

  .talent .ant-select-multiple .ant-select-selection-item {
    height: 100%;
  }

